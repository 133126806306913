import { memo, MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

export const StyledButton = styled.button<{
  primary?: boolean;
  ghost?: boolean;
  big?: boolean;
  rounded?: boolean;
  circular?: boolean;
  disabled?: boolean;
}>`
  font-family: ${props => props.theme.fonts.SourceSansPro};
  font-size: 14px;
  color: ${props => props.theme.colors.secondary};
  text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: none;
  min-height: 60px;
  height: 0;
  padding: 10px 30px;
  justify-content: center;
  min-width: ${props => (props.big ? '195px' : '100px')};
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.ghost
      ? props.theme.colors.secondary
      : props.primary
      ? 'white'
      : props.theme.colors.secondary};

  background-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.ghost
      ? 'transparent'
      : // eslint-disable-next-line no-nested-ternary
      props.primary
      ? props.theme.colors.primary
      : 'black'};

  .text-item {
    font-size: 12px;
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    strong {
      font-size: 14px;
      text-transform: uppercase;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
  ${({ rounded }) => (rounded ? 'border-radius : 30px;' : 'border-radius : 0;')}
  ${({ circular, theme }) =>
    circular &&
    `
      border-radius: 100%;
      width: 70px;
      height: 70px;
      padding: 10px;
      min-width: auto;
      min-height: auto;
      position: absolute;
      right: ${theme.rtl ? 'calc(100% - 45px)' : '0'};
      top: 0;
      transform: translateX(10px);
    `}
 
  ${({ disabled, theme }) =>
    disabled &&
    ` 
    background-color: ${theme.colors.disabledGray};
    cursor: no-drop;
  `}

  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    min-height: 50px;
    padding: 10px 20px;
  }

  &:after {
    background: #fff;
    content: '';
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 0;
  }
  &:hover {
    background-color: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.ghost
        ? 'transparent'
        : props.primary
        ? darken(0.15, props.theme.colors.primary)
        : 'black'};
    ${({ disabled, theme }) =>
      disabled &&
      `
        background-color : ${darken(0.15, theme.colors.disabledGray)};
      `}
    &:after {
      left: 130%;
    }
  }
`;

interface ButtonProps {
  title?: string;
  subtitle?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right' | 'center';
  primary?: boolean;
  ghost?: boolean;
  big?: boolean;
  rounded?: boolean;
  circular?: boolean;
}

function Button({
  title,
  subtitle,
  htmlType = 'button',
  disabled = false,
  onClick = () => {},
  icon,
  primary = true,
  ghost,
  big,
  rounded = false,
  circular = false,
  iconPosition = 'center',
}: ButtonProps) {
  return (
    <StyledButton
      type={htmlType}
      primary={primary}
      ghost={ghost}
      disabled={disabled}
      onClick={onClick}
      rounded={rounded}
      circular={circular}
      big={big}
    >
      {iconPosition === 'left' && icon}
      {iconPosition === 'center' && icon
        ? icon
        : (title || subtitle) && (
            <span className="text-item">
              {title && <strong>{title}</strong>}
              {subtitle && <span>{subtitle}</span>}
            </span>
          )}
      {iconPosition === 'right' && icon}
    </StyledButton>
  );
}

export default memo(Button);
