import { memo, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

interface SeoProps {
  description?: string;
  lang?: 'fr' | 'en';
  title?: string;
  keywords?: Array<string>;
}

function SEO({ description, lang = 'fr', title, keywords = [] }: SeoProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = useMemo(
    () => description || site.siteMetadata.description,
    [description, site.siteMetadata.description],
  );

  const metaTitle = useMemo(
    () => title || site.siteMetadata.title,
    [title, site.siteMetadata.title],
  );

  const titleTemplate = useMemo(
    () => (title ? `%s | ${site.siteMetadata.title}` : undefined),
    [title, site.siteMetadata.t],
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `https://i.imgur.com/eIwE2pm.png`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `https://i.imgur.com/eIwE2pm.png`,
        },
      ].concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : [],
      )}
    />
  );
}

export default memo(SEO);
