import { toast } from 'react-toastify';

const position = toast.POSITION.BOTTOM_RIGHT;

export const loadingToast = (
  msg: string = 'Opération en cours 🤘...',
): number | string => toast.loading(msg, { position });

export const successToast = (id: number | string, msg: string) =>
  toast.update(id, {
    render: msg,
    type: 'success',
    position,
    className: 'success-message',
    isLoading: false,
    autoClose: 1000,
  });

export const errorToast = (id: number | string, msg: string) =>
  toast.update(id, {
    position,
    render: msg,
    type: 'error',
    className: 'danger-message',
    isLoading: false,
    autoClose: 2000,
  });
