import { CSSProperties } from 'react';

interface ArrowProps {
  style?: CSSProperties;
}
export default function Arrow({ style }: ArrowProps) {
  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 30"
      width="33"
      height="30"
      style={style}
    >
      <path d="M 0.85 16.55 L 0.85 15.25 L 30.942 15.25 L 30.942 16.55 L 0.85 16.55 Z M 16.12 1.39 L 17.05 0.47 L 32.591 15.89 L 31.661 16.81 L 16.12 1.39 Z M 31.66 14.973 L 32.59 15.893 L 18.36 30.03 L 17.44 29.11 L 31.66 14.973 Z" />
    </svg>
  );
}
