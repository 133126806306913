import config from '../config';
import { isJSON } from './helpers';

const { API_URL } = config;

export const HTTP_METHODS = Object.freeze({
  GET: { method: 'GET' },
  POST: { method: 'POST' },
  PUT: { method: 'PUT' },
  PATCH: { method: 'PATCH' },
  DELETE: { method: 'DELETE' },
});
export type HTTP_METHODS_KEYS = keyof typeof HTTP_METHODS;

export const CONTENT_TYPE = Object.freeze({
  JSON: { 'Content-Type': 'application/json' },
  FORM_ENCODED: { 'Content-Type': 'application/x-www-form-urlencoded' },
  MULTIPART: { 'Content-Type': 'form/multipart' },
});

export class ResponseError extends Error {
  public response: Response | undefined;

  public status: number | undefined;

  private statusText: string;

  constructor(statusText: string) {
    super(statusText);
    this.statusText = statusText;
  }
}

export const responseMiddleware = async (response: Response) => {
  if (response.ok) return response;

  if (response.status === 401 || response.status === 403) {
    // logout
  }

  // console.log({ response });
  const error = new ResponseError(response.statusText);
  error.status = response.status;
  let res = null;
  try {
    res = JSON.parse(await response.text());
  } catch (e) {
    res = await response.text();
  }

  error.response = res || {};
  return Promise.reject(error);
};

export const request = (url: string, options: object) =>
  fetch(`${API_URL}/${url}`, options)
    .then(responseMiddleware)
    .then((res: any) => res.text())
    // eslint-disable-next-line no-nested-ternary
    .then(e => (!e ? {} : isJSON(e) ? JSON.parse(e) : e));

export const requestForBlob = (url: string, options: object) =>
  fetch(`${API_URL}/${url}`, options)
    .then(responseMiddleware)
    .then(res => res.blob());

export const http = (
  url: string,
  method: typeof HTTP_METHODS[HTTP_METHODS_KEYS],
  data: { body?: any; headers?: any } = {
    headers: { ...CONTENT_TYPE.JSON },
    body: '',
  },
  forBlob = false,
) => {
  const { headers = { ...CONTENT_TYPE.JSON }, body = '' } = data;

  // console.log({ headers, body });

  return (forBlob ? requestForBlob : request)(url, {
    ...method,
    headers,
    // eslint-disable-next-line no-nested-ternary
    body: forBlob ? body : body !== '' ? JSON.stringify(body) : '',
  });
};
