import { CSSProperties, memo, ReactNode } from 'react';
import styled from 'styled-components';

interface SvgIconProps {
  iconColor?: string;
  children?: ReactNode;
  style?: CSSProperties;
  notRtl?: boolean;
}

const StyledSvgIcon = styled.span<{
  iconColor?: string;
  notRtl?: boolean;
}>`
  display: inline-flex;
  svg {
    transform: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.theme.rtl
        ? props.notRtl
          ? 'rotate(0)'
          : 'rotate(180deg)'
        : 'rotate(0)'};
    path {
      fill: ${props => props.iconColor};
    }
  }
`;

function SvgIcon({
  iconColor = '#000',
  children,
  style = {},
  notRtl,
}: SvgIconProps) {
  return (
    <StyledSvgIcon notRtl={notRtl} iconColor={iconColor} style={style}>
      {children}
    </StyledSvgIcon>
  );
}

export default memo(SvgIcon);
